import Splide from "@splidejs/splide";

export default class Slider {
  constructor() {
    document.addEventListener("DOMContentLoaded", function () {
      if (document.querySelector(".splide-teams")) {
        const splideTeams = new Splide(".splide-teams", {
          type: "loop",
          speed: 500,
          autoplay: false,
          interval: 5000,
          gap: "2em",
        });

        splideTeams.mount();
      }

      if (document.querySelector(".hero-slider")) {
        const heroSlider = new Splide(".hero-slider", {
          type: "loop",
          speed: 500,
          autoplay: true,
          interval: 5000,
          gap: "2em",
          arrows: true,
          pagination: true,
          classes: {
            arrows: 'splide__arrows hero-slider__arrows',
            arrow : 'splide__arrow hero-slider__arrow',
            pagination: 'splide__pagination hero-slider__pagination',
            page: 'splide__pagination__page hero-slider__pagination__page'
          },
        });

        heroSlider.mount();
      }

      if (document.querySelector(".main-slider")) {
        const main = new Splide(".main-slider", {
          type: "fade",
          rewind: true,
          pagination: false,
          arrows: false,
          classes: {
            arrows: 'splide__arrows main-slider__arrows',
            arrow : 'splide__arrow main-slider__arrow'
          },
        });

        const thumbnails = new Splide(".thumbnail-slider", {
          fixedWidth: 100,
          fixedHeight: 100,
          gap: 10,
          rewind: true,
          pagination: false,
          cover: false,
          isNavigation: true,
          arrows: false,
          breakpoints: {
            600: {
              fixedWidth: 60,
              fixedHeight: 60,
            },
          },
        });

        main.sync(thumbnails);
        main.mount();
        thumbnails.mount();
      }

      if (document.querySelector(".recommendation-slider")) {
        const recommendation = new Splide(".recommendation-slider", {
          perPage: 5,
          rewind: false,
          gap: 10,
          breakpoints: {
            600: {
              perPage: 2
            },
            1024: {
              perPage: 4
            },
          },
          arrows: true,
          classes: {
            arrows: 'splide__arrows recommendation-slider__arrows',
            arrow : 'splide__arrow recommendation-slider__arrow',
            pagination: 'splide__pagination recommendation-slider__pagination',
            page: 'splide__pagination__page recommendation-slider__pagination__page'
          },
        });

        recommendation.mount();
      }

      if (document.querySelector(".references-slider")) {
        const referencesSlider = new Splide(".references-slider", {
          type: "loop",
          speed: 500,
          autoplay: true,
          interval: 3000,
          perPage: 5,
          perMove: 1,
          gap: "1.5rem",
          arrows: true,
          pagination: true,
          classes: {
            arrows: 'splide__arrows references-slider__arrows',
            arrow : 'splide__arrow references-slider__arrow',
            pagination: 'splide__pagination references-slider__pagination',
            page: 'splide__pagination__page references-slider__pagination__page'
          },
          breakpoints: {
            1024: {
              perPage: 4,
            },
            768: {
              perPage: 3,
            },
            640: {
              perPage: 1,
            }
          },
        });

        referencesSlider.mount();
      }

      if (document.querySelectorAll('.variant-slider .splide__slide').length >= 4) {
        const variantSlider = new Splide('.variant-slider', {
          perPage: 3,
          rewind: false,
          gap: 10,
          arrows: true,
          pagination: true,
          classes: {
            arrows: 'splide__arrows variant-slider__arrows',
            arrow : 'splide__arrow variant-slider__arrow',
            pagination: 'splide__pagination variant-slider__pagination',
            page: 'splide__pagination__page variant-slider__pagination__page'
          },
          breakpoints: {
            600: {
              perPage: 2
            },
            1024: {
              perPage: 3
            },
          },
        });

        variantSlider.mount();
      }
    });
  }
}
